import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"

import Tarot from "../images/regresion.jpg"

const Services = () => {
  return (
    <Layout>
      <SEO title="Contactar a Spiritual Worker" />

      <div className="w-full mx-auto bg-gray-900 pt-5 pt-10 pb-10 lg:pt-20 lg:pb-20">
        <h2 className="titles text-gray-200 text-2xl lg:text-4xl text-center pb-5 lg:pb-20">
          Contacto
        </h2>
        <div className="w-full lg:w-10/12 lg:flex mx-auto">
          <div className="hidden md:block w-full lg:w-1/3 rounded-lg p-5">
            <img src={Tarot} alt="Contacto de Spiritual Worker" className="" />
            <p className="text-sm text-yellow-100 p-0 py-4">
              &ldquo;El valor de todo servicio yace en el espíritu con el que se
              sirva y no en la importancia o magnitud del servicio. Hasta la más
              humilde tarea se vuelve sagrada, gozoza y próspera si se hace con
              amor.&rdquo; - Charles Fillmore
            </p>
          </div>

          <div className="w-full lg:w-2/3 mt-5 lg:mt-0">
            <p>
              Se puede poner en contacto con Spiritual Worker por medio de
              Whatsapp escribiendo al{" "}
              <a
                className="whatsapp underline"
                href="https://api.whatsapp.com/send?phone=50688355826&text=Contacto%20del%20sitio%20web"
              >
                +506 8835 5826
              </a>
              , o enviando un email a{" "}
              <a className="underline" href="mailto:info@spiritualworker.org">
                info@spiritualworker.org
              </a>
            </p>
            <p>Si prefiere también puede usar el siguiente formulario:</p>

            <div className="contact-form bg-gray-700 px-5 py-3 pb-10 mt-16">
              <form
                name="Contacto"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                action="/contacto-gracias/"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="Contacto" />
                <label htmlFor="nombre">Su nombre completo:</label>
                <input
                  type="text"
                  name="nombre"
                  id="nombre"
                  placeholder="Nombre completo"
                  className="w-10/12 md:w-1/2"
                />
                <label htmlFor="email">Su email:</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Su email"
                  className="w-10/12 md:w-1/2"
                />
                <label htmlFor="motivo">Intención del contacto:</label>
                <select
                  name="motivo"
                  id="motivo"
                  className="w-10/12  md:w-1/2 px-2 py-1 text-lg mb-10"
                >
                  <option value="Cita">Quiero una cita</option>
                  <option value="Comentario">Quiero dejar un comentario</option>
                </select>

                <label htmlFor="mensaje">Su Mensaje:</label>
                <textarea
                  name="mensaje"
                  id="mensaje"
                  className="w-10/12  md:w-2/3 px-2 py-1 h-48 mb-10 block"
                ></textarea>
                <input
                  type="submit"
                  className="transition-colors duration-500 ease-in-out inline-block px-3 py-2 bg-blue-700 hover:bg-gray-900 text-white  hover:text-yellow-200 text-center  "
                  value="Enviar"
                  name="submit"
                />
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default Services
